import FileSaver from 'file-saver';
import { STACK_BASE_PATH_LOCAL, STACK_BASE_PATH_PROD } from './constants';
import { getMomentDate } from './date';

export const getTinymceApiKey = (devEnv) =>
  devEnv
    ? 'lg7qrggopsxjyy497dknb6cf3kwh9fhx7h3jdhnyjc4dwgun'
    : 'lg7qrggopsxjyy497dknb6cf3kwh9fhx7h3jdhnyjc4dwgun';

export const getDocumentBaseUrl = (devEnv) =>
  devEnv ? STACK_BASE_PATH_LOCAL : STACK_BASE_PATH_PROD;

export const validateAge = (dateOfBirth) => {
  if (dateOfBirth) {
    const dob = getMomentDate(dateOfBirth);
    const today = getMomentDate();
    const age = today.diff(dob, 'years');
    if (age < 18) {
      return 'Age must be greater than or equal to 18';
    }
  }
  return true;
};

export const validateAgeOver14 = (dateOfBirth) => {
  if (dateOfBirth) {
    const dob = getMomentDate(dateOfBirth);
    const today = getMomentDate();
    const age = today.diff(dob, 'years');
    if (age < 14) {
      return 'Age must be greater than or equal to 14';
    }
  }
  return true;
};

export const validateAgeOver18OrGuardian = (dateOfBirth) => {
  if (dateOfBirth) {
    const dob = getMomentDate(dateOfBirth);
    const today = getMomentDate();
    const age = today.diff(dob, 'years');
    if (age < 18) {
      return "Age must be greater than or equal to 18. Please enter their guardian's information in this entire form instead.";
    }
  }
  return true;
};

export const splitName = (name = '') => {
  const [firstName, ...lastName] = name.split(' ').filter(Boolean);
  return {
    firstName,
    lastName: lastName.join(' '),
  };
};

export const hasOwnProperty = (obj, key) =>
  Object.prototype.hasOwnProperty.call(obj, key);

export const alphaNumericSortFunc = (x, y, order) => {
  const a = x.toString();
  const b = y.toString();
  if (order === 'asc') {
    return b.localeCompare(a, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

export const getLastItem = (array) => {
  if (array?.length) {
    return array[array.length - 1];
  }
  return undefined;
};

export const getNextId = (lastId, defaultValue) => {
  if (lastId || lastId === 0) {
    if (typeof lastId === 'number') {
      return Number(lastId) + 1;
    }
    if (typeof lastId === 'string') {
      const matches = lastId.match(/\d+/g);
      if (matches?.length) {
        const lastNumber = Number(getLastItem(matches));
        const nextNumber = lastNumber + 1;
        return lastId.replace(new RegExp(`${lastNumber}$`), nextNumber);
      }
    }
  }

  return defaultValue;
};

export const isDeletedEmail = (email) =>
  email && email.includes('@deleted.stack.angellist.com');

export const getValidWebsiteUrl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }

  return url;
};

export const saveFile = (
  content,
  fileName,
  type = 'text/plain;charset=utf-8',
) => {
  FileSaver.saveAs(new Blob([content], { type }), fileName, {
    autoBom: false,
  });
};

export const cloneList = (data) => {
  if (data) {
    return JSON.parse(JSON.stringify(data));
  }
  return [];
};

export const cloneObject = (data) => {
  if (data) {
    return JSON.parse(JSON.stringify(data));
  }
  return {};
};
