import React, { createRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

export const useLoaderFullWidth = (defaultWidth) => {
  const [loaderWidth, setWidth] = useState(defaultWidth);
  const refEl = createRef();

  useLayoutEffect(() => {
    function updateWidth() {
      if (refEl && refEl.current) {
        const width = refEl.current.offsetWidth;
        setWidth(width);
      }
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, [refEl]);

  return [refEl, loaderWidth];
};

const RowLoader = (props) => {
  const {
    width,
    minWidth,
    maxWidth,
    isRandomWidth,
    height,
    rectHeight,
    radius,
    className,
  } = props;
  const w = isRandomWidth ? width * Math.random() * (3 - 0.7) + 0.7 : width;
  let wt = Math.max(w, minWidth);
  if (maxWidth) {
    wt = Math.min(wt, maxWidth);
  }
  const ht = rectHeight || height;
  return (
    <ContentLoader
      speed={2}
      height={height}
      className={className}
      width={wt}
      style={{ width: `${wt}px`, height: `${height}px` }}
    >
      <rect x="0" y="0" rx={radius} ry={radius} width={wt} height={ht} />
    </ContentLoader>
  );
};

RowLoader.defaultProps = {
  width: 150,
  height: 14,
  minWidth: 60,
  maxWidth: null,
  rectHeight: null,
  radius: 2,
  isRandomWidth: true,
  className: '',
};

RowLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  rectHeight: PropTypes.number,
  radius: PropTypes.number,
  isRandomWidth: PropTypes.bool,
  className: PropTypes.string,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

export const FieldLoader = ({ height, defaultWidth }) => {
  const [looderWrapEl, loaderWidth] = useLoaderFullWidth(defaultWidth);

  return (
    <div className="w-100" ref={looderWrapEl}>
      <RowLoader
        height={height}
        isRandomWidth={false}
        width={loaderWidth}
        radius={0}
      />
    </div>
  );
};

FieldLoader.defaultProps = {
  height: 40,
  defaultWidth: 200,
};

FieldLoader.propTypes = {
  height: PropTypes.number,
  defaultWidth: PropTypes.number,
};

export const LazyText = (props) => {
  const { loading, text, ...rest } = props;

  if (loading) {
    return <RowLoader width={50} {...rest} />;
  }

  return text;
};

LazyText.defaultProps = {
  loading: false,
  text: '',
  ...RowLoader.defaultProps,
};

LazyText.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.any,
  ...RowLoader.propTypes,
};

export default RowLoader;
